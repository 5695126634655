import React from 'react';

export interface FilterProps {
    options: Array<string>;
    selectedOption: string;
    onChange: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
}

const Filter: React.FC<FilterProps> = ({options, selectedOption, onChange}) => {

    return(
        <div className="a-filter">
            <select 
                className="a-filter-dropdown"
                id="filterSelect"
                onChange={onChange}
                value={selectedOption}
            >
                {options.map((filter, key) => {
                    return (
                        <option 
                            className="a-filter-option" 
                            key={key} 
                            value={filter}
                        >
                            {filter}
                        </option>
                    )
                })}
            </select>
        </div>
    );
}

export default Filter;