import React from 'react';
import FadeIn from 'react-fade-in';
import Logo from '../components/Logo';
import SideMenu from '../components/Menu';

interface ContactPageProps {
  translate: (key: string) => string
}

const ContactPage: React.FC<ContactPageProps> = ({ translate }) => {
    const className = 'a-text';

    return(
        <div className="p-container">            
            <SideMenu translate={translate} />
            <Logo />
            <FadeIn>
                <h1 className="a-headline">
                    {translate('sideMenuContact')}
                </h1>
                <div className={className}>
                    <p>
                        <a href="mailto:contact@maria-antonia.art">contact@maria-antonia.art</a>
                    </p>
                </div>
            </FadeIn>
        </div>
    );
}

export default ContactPage;