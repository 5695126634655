import React, { useContext } from 'react';
import { LangContext } from '../context/lang';
import Logo from '../components/Logo';
import SideMenu from '../components/Menu';
import FadeIn from 'react-fade-in';

interface ImprintPageProps {
  translate: (key: string) => string
}

const ImprintPage: React.FC<ImprintPageProps> = ({ translate }) => {
    const lang = useContext(LangContext);
    const className = 'a-text';
    let country = 'SWITZERLAND';

    switch (lang.state.language) {
        case 'EN':
            country = 'SWITZERLAND';
            break;
        case 'FR':
            country = 'LA SUISSE';
            break;
        default:
        case 'DE':
            country = 'SCHWEIZ';
            break;
    }

    return(
        <div className="p-container">            
            <SideMenu translate={translate} />
            <Logo />
            <FadeIn>
                <h1 className="a-headline">
                    {translate('sideMenuImprint')}
                </h1>
                <div className={className}>
                    <p>
                        Maria-Antonia Mardaleichvili Scherber<br />
                        Metzgergasse 3<br />
                        9320 Arbon<br />
                        {country}
                    </p>
                    <p>
                        <a href="mailto:imprint@maria-antonia.art">imprint@maria-antonia.art</a>
                    </p>
                </div>
            </FadeIn>
        </div>
    );
}

export default ImprintPage;