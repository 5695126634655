import React, { useContext } from 'react';
import { LangContext } from '../context/lang';
import Logo from '../components/Logo';
import SideMenu from '../components/Menu';
import FadeIn from 'react-fade-in';

interface PoemPageProps {
  translate: (key: string) => string
}

const PoemPage: React.FC<PoemPageProps> = ({ translate }) => {
    const lang = useContext(LangContext);
    let className = 'a-text hidden-content';

    const poem = () => {
        switch (lang.state.language) {
            case 'EN':
                return <div className={className}>
                    <p>
                        Painting.<br />
                        Body.<br />
                        Beauty.<br />
                        Sensual perception.
                    </p>
                    <p>
                        My borders - your borders?<br />
                        Where am I and what?<br />
                        My thoughts hover<br />
                        In plasma, they call it world.<br />
                        I see myself in the mirror and am transparent.<br />
                        What I feel and think is outside of my body<br />
                        Somewhere in this space.<br />
                        I don't stop being where my skin stops, finishes.<br />
                        I'm also there, where I look at.<br />
                        I'm also the one, I think of.
                    </p>
                    <p>
                        Colors are nourishment for eyes,<br />
                        Energies,<br />
                        Transport, what I wanted to say<br />
                        But did not know how.<br />
                        And they hear it anyway.<br />
                        I can not think, what I paint,<br />
                        Can only sense it and<br />
                        Still need to enter much more into<br />
                        Doing, into painting.
                    </p>
                    <p>
                        I have no boundaries around me,<br />
                        I'm nothing and everybody.<br />
                        My body sometimes holds me tight,<br />
                        Or my soul holds on to it.<br />
                        When it moves, it feels good.<br />
                        When my hands touch something,<br />
                        They realize what it is<br />
                        And become one with it<br />
                        Out of love.
                    </p>
                </div>

            case 'DE':
                return <div className={className}>
                    <p>
                        Malerei.<br />
                        K&ouml;rper.<br />
                        Sch&ouml;nheit.<br />
                        Sinnliche Wahrnehmung.<br />
                        Meine Grenzen - deine Grenzen?<br />
                        Wo bin ich und was?
                    </p>
                    <p>
                        Meine Gedanken schweben<br />
                        Im Plasma, sie nennen es Welt.<br />
                        Sehe mich im Spiegel und bin durchsichtig.<br />
                        Was ich f&uuml;hle und denke<br />
                        Ist au&szlig;erhalb meines K&ouml;rpers<br />
                        Irgendwo in diesem Raum.<br />
                        Ich h&ouml;re nicht auf zu sein,<br />
                        Wo meine Haut aufh&ouml;rt, abschlie&szlig;t.<br />
                        Bin auch dort, wo ich hinsehe.<br />
                        Bin auch der, an den ich denke.
                    </p>
                    <p>
                        Farben sind Nahrung der Augen,<br />
                        Energien,<br />
                        Transportieren, was ich sagen wollte,<br />
                        Aber nicht zu sagen wusste.<br />
                        Und sie h&ouml;ren es doch.<br />
                        Ich kann nicht denken, was ich male,<br />
                        Kann es nur f&uuml;hlen und<br />
                        Mu&szlig; noch viel mehr hinein zum<br />
                        Handeln, zum Bild.
                    </p>
                    <p>
                        Ich habe keine Grenze um mich herum<br />
                        Bin nichts und jeder.<br />
                        Mein K&ouml;rper h&auml;lt mich manchmal fest<br />
                        Oder meine Seele sich an ihm.<br />
                        Wenn er sich bewegt, f&uuml;hlt er sich gut an.<br />
                        Wenn meine H&auml;nde etwas ber&uuml;hren<br />
                        Erfahren sie, was es ist<br />
                        Und werden eins mit ihm<br />
                        Aus Liebe.
                    </p>
                </div>
            case 'FR': 
                return <div className={className}>
                    <p>
                        Peinture<br />
                        Corps<br />
                        Beaut&eacute;<br />
                        Perception sensible<br />
                        Mes fronti&egrave;res - tes fronti&egrave;res?<br />
                        Je suis o&ugrave; et je suis quoi?
                    </p>
                    <p>
                        Mes pens&eacute;s planent<br />
                        Dans le plasma, ils l´appellent monde.<br />
                        Je me vois dans le miroir et je suis transparente.<br />
                        Ce que je sens et pense est dehors de mon corps<br />
                        En quelque part de cet espace.<br />
                        Je ne cesse pas d´&ecirc;tre l&aacute;<br />
                        O&ugrave; ma peau cesse, termine.<br />
                        Je suis aussi l&aacute;, o&ugrave; je regarde.<br />
                        Je suis aussi lui, en qui je pense.
                    </p>
                    <p>
                        Couleurs sont nutrition des yeux,<br />
                        Energie,<br />
                        Transportent ce que je voulais dire<br />
                        Mais ne savais pas comment.<br />
                        Et ils l´entendent quand m&ecirc;me.<br />
                        Je ne peux pas penser ce que je peins,<br />
                        Je ne peux que le sentir et<br />
                        Je dois entrer encore beaucoup plus aux<br />
                        Faire, a la peinture.
                    </p>
                    <p>
                        Je n´ai pas des fronti&egrave;res autour de moi.<br />
                        Je suis rien et tout.<br />
                        Mon corps tient ferme &agrave; moi quelque fois<br />
                        Ou mon &acirc;me &aacute; lui.<br />
                        Quand il se bouge, il se sens bien.<br />
                        Quand mes mains touchent quelque chose<br />
                        Ils apprennent ce que c´est<br />
                        Et deviennent un<br />
                        par amour.
                    </p>
                </div>
        
            default:
                return;
        }
    }

    return(
        <div className="p-container">
            <SideMenu translate={translate} />
            <Logo />
            <FadeIn>
                <h1 className="a-headline">
                    {translate('sideMenuPoem')}
                </h1>
                {poem()}
            </FadeIn> 
        </div>
    );   
}

export default PoemPage;