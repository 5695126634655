import React from 'react';
import FadeIn from 'react-fade-in';
import Logo from '../components/Logo';
import SideMenu from '../components/Menu';
import Artwork from '../components/Artwork';

interface StartpageProps {
  translate: (key: string) => string
}

const Startpage: React.FC<StartpageProps> = ({ translate }) => {
    return(
        <div className="p-container">            
            <SideMenu translate={translate} />
            <Logo />
            <FadeIn>
                <Artwork />
            </FadeIn>
        </div>
    );
}

export default Startpage;