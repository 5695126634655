import React, { FC, useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { LangContext } from './context/lang';

import Startpage from './pages/Startpage';
import Page404 from './pages/404';
import PoemPage from './pages/Poem';
import BioPage from './pages/Bio';
import ExhibitionsPage from './pages/Exhibitions';
import ContactPage from './pages/Contact';
import ImprintPage from './pages/Imprint';
import './css/app.scss';

const App: FC = () => {
    const { dispatch: { translate } } = useContext(LangContext);
  
    return (
        <BrowserRouter basename="/">
            <Switch>
                <Route path="/" exact><Startpage translate={translate} /></Route>
                <Route path="/poem"><PoemPage translate={translate} /></Route>
                <Route path="/bio"><BioPage translate={translate} /></Route>
                <Route path="/exhibitions"><ExhibitionsPage translate={translate} /></Route>
                <Route path="/contact"><ContactPage translate={translate} /></Route>
                <Route path="/imprint"><ImprintPage translate={translate} /></Route>
                <Route path="*"><Page404 translate={translate} /></Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
