import React, { useContext } from 'react';
import { LangContext } from '../context/lang';
import Logo from '../components/Logo';
import SideMenu from '../components/Menu';
import FadeIn from 'react-fade-in';

interface BioPageProps {
  translate: (key: string) => string
}

const BioPage: React.FC<BioPageProps> = ({ translate }) => {
    const lang = useContext(LangContext);
    const className = 'a-text a-text--bio';

    const bio = () => {
        switch (lang.state.language) {
            case 'EN':
                return <div className={className}>
                    <table>
                        <tbody>
                        <tr>
                            <td>Since 2022</td>
                            <td>Living in Arbon, Switzerland</td>
                        </tr>
                        <tr>
                            <td>2016-2022</td>
                            <td>Living in Germany</td>
                        </tr>
                        <tr>
                            <td>2015-2018</td>
                            <td>Studies of Art Therapy at the KHSB, Berlin - Germany</td>
                        </tr>
                        <tr>
                            <td>2012-2015</td>
                            <td>Living in Switzerland</td>
                        </tr>
                        <tr>
                            <td>2005-2012</td>
                            <td>
                            Living in Spain; Trainings: Aromatherapy
                            </td>
                        </tr>
                        <tr>
                            <td>2002-2005</td>
                            <td>Studies of Physiotherapy, Dresden - Germany</td>
                        </tr>
                        <tr>
                            <td>2001</td>
                            <td>Diploma of the Academy of Fine Arts, Dresden - Germany</td>
                        </tr>
                        <tr>
                            <td>2001</td>
                            <td>
                            Studies at Ecole Nationale Superieure des Beaux Arts, Paris
                            </td>
                        </tr>
                        <tr>
                            <td>1999</td>
                            <td>Studies at Royal College of Arts, London</td>
                        </tr>
                        <tr>
                            <td>1996-2001</td>
                            <td>
                            Studies at Hochschule f&uuml;r Bildende K&uuml;nste, Dresden -
                            Germany
                            </td>
                        </tr>
                        <tr>
                            <td>1991-1996</td>
                            <td>Studies at K&uuml;nstlerische Abendschule, Jena - Germany</td>
                        </tr>
                        <tr>
                            <td>1976</td>
                            <td>Born in Dresden, Germany</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            case 'DE':
                return <div className={className}>
                    <table>
                        <tbody>
                        <tr>
                            <td>Seit 2022</td>
                            <td>In Arbon, Schweiz lebend</td>
                        </tr>
                        <tr>
                            <td>2016-2022</td>
                            <td>In Deutschland lebend</td>
                        </tr>
                        <tr>
                            <td>2015-2018</td>
                            <td>Studium der Kunsttherapie an der KHSB, Berlin</td>
                        </tr>
                        <tr>
                            <td>2012-2015</td>
                            <td>In der Schweiz lebend</td>
                        </tr>
                        <tr>
                            <td>2005-2012</td>
                            <td>
                            In Spain lebend; Weiterbildungen: Aromatherapie
                            </td>
                        </tr>
                        <tr>
                            <td>2002-2005</td>
                            <td>Ausbildung zur Physiotherapeutin, Dresden</td>
                        </tr>
                        <tr>
                            <td>2001</td>
                            <td>
                            Diplom Bildende Kunst / Malerei der Hochschule f&uuml;r Bildende
                            K&uuml;nste, Dresden
                            </td>
                        </tr>
                        <tr>
                            <td>2001</td>
                            <td>
                            Studium an der Ecole Nationale Sup&eacute;rieure des Beaux Arts,
                            Paris
                            </td>
                        </tr>
                        <tr>
                            <td>1999</td>
                            <td>Studium am Royal College of Arts, London</td>
                        </tr>
                        <tr>
                            <td>1996-2001</td>
                            <td>
                            Studium an der Hochschule f&uuml;r Bildende K&uuml;nste, Dresden
                            </td>
                        </tr>
                        <tr>
                            <td>1991-1996</td>
                            <td>Vorstudium in der K&uuml;nstlerische Abendschule, Jena</td>
                        </tr>
                        <tr>
                            <td>1976</td>
                            <td>Geboren in Dresden</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            case 'FR': 
                return <div className={className}>
                    <table>
                        <tbody>
                        <tr>
                            <td>D&eacute;s 2022</td>
                            <td>R&eacute;sidante a Arbon, Suisse</td>
                        </tr>
                        <tr>
                            <td>2016-2022</td>
                            <td>R&eacute;sidante en Allemagne</td>
                        </tr>
                        <tr>
                            <td>2015-2018</td>
                            <td>
                            &Eacute;tudes du Therapie D'Art au KHSB, Berlin - Allemagne
                            </td>
                        </tr>
                        <tr>
                            <td>2012-2015</td>
                            <td>R&eacute;sidante en Suisse</td>
                        </tr>
                        <tr>
                            <td>2005-2012</td>
                            <td>R&eacute;sidante en Espagne</td>
                        </tr>
                        <tr>
                            <td>2002-2005</td>
                            <td>
                            &Eacute;tudes de Kin&eacute;sith&eacute;rapie, Dresden -
                            Allemagne
                            </td>
                        </tr>
                        <tr>
                            <td>2001</td>
                            <td>
                            Dipl&ocirc;me de l'Ecole des Beaux Arts HfBK, Dresden -
                            Allemagne
                            </td>
                        </tr>
                        <tr>
                            <td>2001</td>
                            <td>
                            &Eacute;tudes au Ecole Nationale Superieure des Beaux Arts,
                            Paris
                            </td>
                        </tr>
                        <tr>
                            <td>1999</td>
                            <td>&Eacute;tudes au Royal College of Arts, London</td>
                        </tr>
                        <tr>
                            <td>1996-2001</td>
                            <td>
                            &Eacute;tudes au Hochschule f&uuml;r Bildende K&uuml;nste,
                            Dresden - Allemagne
                            </td>
                        </tr>
                        <tr>
                            <td>1991-1996</td>
                            <td>
                            &Eacute;tudes au K&uuml;nstlerische Abendschule, Jena -
                            Allemagne
                            </td>
                        </tr>
                        <tr>
                            <td>1976</td>
                            <td>N&eacute;e &agrave; Dresden, Allemagne</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
        
            default:
                return;
        }
    }

    return(
        <div className="p-container">            
            <SideMenu translate={translate} />
            <Logo />
            <FadeIn>
                <h1 className="a-headline">
                    {translate('sideMenuBio')}
                </h1>
                {bio()}
            </FadeIn>
        </div>
    );
}

export default BioPage;