import React from 'react';
import { Link } from 'react-router-dom';

const Logo: React.FC = () => {

    return(
        <div className="a-image-logo">
            <Link to="/">
                <img src="assets/maria-antonia_sign.webp" alt="Maria-Antonia" />
            </Link>
        </div>
    );
}

export default Logo;