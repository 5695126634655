import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { LangContext } from '../context/lang';

interface MenuProps {
  translate: (key: string) => string
}

const Menu: React.FC<MenuProps> = ({translate}) => {
    const { state: { language }, dispatch: { setLanguage } } = React.useContext(LangContext);
    const [isMenuOpen, setMenuIsOpen] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const history = useHistory();

    const chooseLanguageHandler = (value: string) => {
        setLanguage(value);
    }

    const handleInputClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setMenuIsOpen(!isMenuOpen);
    }

    isMenuOpen ? document.body.style.overflowY = "hidden" : document.body.style.overflowY = "scroll";

    const handleMenuClick = (e: React.MouseEvent<HTMLAnchorElement>, url: string) => {
        e.preventDefault();
        history.push(url);
        inputRef.current?.click();
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => inputRef.current?.click()
    });

    const navLinks = [
        {route: '/poem', translationKey: 'sideMenuPoem'}, 
        {route: '/bio', translationKey: 'sideMenuBio'}, 
        {route: '/exhibitions', translationKey: 'sideMenuExhibitions'}, 
        {route: '/contact', translationKey: 'sideMenuContact'}, 
        {route: '/imprint', translationKey: 'sideMenuImprint'}
    ];

    const langLinks = [
        {id: 'EN', translationKey: 'languageMenuShortEnglish'}, 
        {id: 'DE', translationKey: 'languageMenuShortGerman'}, 
        {id: 'FR', translationKey: 'languageMenuShortFrench'}, 
    ]

    return(
        <nav className="m-menu-side" role="navigation" {...swipeHandlers}>
            <div className="m-menu-side__toggle">

                <input type="checkbox" ref={inputRef} onClick={handleInputClick} />

                <span className="m-menu-side__hamburger-line-1"></span>
                <span className="m-menu-side__hamburger-line-2"></span>
                <span className="m-menu-side__hamburger-line-3"></span>

                <div className="m-menu-side__menu">
                    <ul>
                        <li>
                            <NavLink 
                                className="m-menu-side__menu-button--home" 
                                to="/" 
                                onClick={(ev) => handleMenuClick(ev, '/')}
                            >
                                {translate('sideMenuHome')}
                            </NavLink>
                        </li>
                    </ul>
                    <ul>
                        {navLinks.map((link, index) => {
                            return (
                                <li key={'sidemenu-main-link' + index}>
                                    <NavLink 
                                        className="m-menu-side__menu-button" 
                                        to={link.route} 
                                        onClick={(ev) => handleMenuClick(ev, link.route)}
                                    >
                                        {translate(link.translationKey)}
                                    </NavLink>
                                </li>
                            )
                        })}
                    </ul>
                    <ul>
                        {langLinks.map((link, index) => {
                            return (
                                <li key={'sidemenu-lang-link' + index}>
                                    <button 
                                        id={link.id} 
                                        className={language === link.id ? 'm-menu-side__menu-button m-menu-side__menu-button--active' : 'm-menu-side__menu-button'} 
                                        onClick={() => chooseLanguageHandler(link.id)}
                                    >
                                        {translate(link.translationKey)}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Menu;