import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface Page404Props {
  translate: (key: string) => string
}

const Page404: FC<Page404Props> = ({ translate }) => {
  return(
    <section className="p-404">
      <div className="p-container">
        <h1>404</h1>
        <p>{translate('pageDoesNotExist')}</p>
        <p><Link to="/">{translate('returnToStartpage')}</Link></p>
      </div>
    </section>
  );
}

export default Page404;